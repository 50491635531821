import { createContext, useContext, ReactNode, FC } from 'react';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

import { useLocalStorage } from './useLocalStorage';

export interface IUser {
  id: string;
  name: string;
  username: string;
  email: string;
  permissions: {
    role: 'admin' | 'user';
    pages: {
      [key: string]: {
        showPage: boolean;
        actions: {
          [key: string]: boolean;
        } | null;
      };
    };
  };
}

interface IGlobalProvider {
  user: IUser | null;
  login: (data: IUser, token: string) => void;
  logout: () => void;
  token: {
    token: string;
  } | null;
  showAlert: (config: SweetAlertOptions) => Promise<SweetAlertResult>;
}

const initialValue: IGlobalProvider = {
  user: null,
  login: () => {},
  logout: () => {},
  token: null,
  showAlert: (config: SweetAlertOptions) => Swal.fire(config),
};

const GlobalContext = createContext<IGlobalProvider>(initialValue);

interface Props {
  children: ReactNode;
}

export const GlobalProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);
  const [token, setToken] = useLocalStorage('token', null);

  const login = (data: IUser, token: string) => {
    setUser(data);
    setToken({ token });
  };

  const logout = async () => {
    setUser(null);
    setToken(null);
  };

  const showAlert = (config: SweetAlertOptions) => {
    return Swal.fire(config);
  };

  const value: IGlobalProvider = {
    user,
    login,
    logout,
    token,
    showAlert,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  return useContext(GlobalContext);
};
