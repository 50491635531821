import { FC, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useGlobal } from '../../hooks/useGlobal';
import { IUser } from '../../hooks/useGlobal';
import { Avatar, Dropdown, Navbar } from 'flowbite-react';

interface IHeader {
  user: IUser;
}

const menuItems = [
  { to: '/', label: 'Cihaz Listesi' },
  { to: '/numbers', label: 'Numaralar' },
  { to: '/statistics', label: 'İstatistikler' },
  { to: '/settings', label: 'Ayarlar' },
];

export const Header: FC<IHeader> = ({ user }: IHeader) => {
  const { logout } = useGlobal();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { username, email, permissions } = user;

  // Filter menu items based on user permissions
  const filteredMenuItems = useMemo(() => {
    if (!permissions) return [];

    return menuItems.filter((item) => {
      return (
        item.to === '/' ||
        (permissions && permissions.pages[item.to.replace('/', '')]?.showPage)
      );
    });
  }, [permissions]);

  // Handle logout
  const handleLogout = () => {
    if (confirm('Çıkış yapmak istediğinize emin misiniz?')) {
      logout();
      navigate('/', { replace: true });
    }
  };

  return (
    <Navbar className="w-full fixed z-30 bg-white border-b border-gray-200">
      <div className="flex justify-between items-center max-w-screen-xl mx-auto w-full">
        <Navbar.Brand as={Link} to="/">
          <span className="self-center whitespace-nowrap text-xl font-semibold">
            WPInvite
          </span>
        </Navbar.Brand>
        <div className="flex md:order-2">
          <Dropdown
            arrowIcon={false}
            inline
            label={
              <Avatar
                alt=""
                img={require('../../assets/images/user-default.jpg')}
                rounded
              />
            }
          >
            <Dropdown.Header>
              <span className="block text-sm">{username}</span>
            </Dropdown.Header>
            <Dropdown.Item onClick={handleLogout}>Çıkış Yap</Dropdown.Item>
          </Dropdown>
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse>
          {filteredMenuItems.map((item) => {
            return (
              <Navbar.Link
                key={item.to}
                to={item.to}
                as={Link}
                active={pathname === item.to}
              >
                {item.label}
              </Navbar.Link>
            );
          })}
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
