export const canView = (user: any, pageKey: string): boolean => {
  if (!user || !user.permissions || !user.permissions.pages) {
    return false;
  }

  const pagePermissions = user.permissions.pages[pageKey];
  return pagePermissions?.showPage === true;
};

export const isAdmin = (user: any): boolean => {
  if (!user || !user.permissions) {
    return false;
  }

  return user.permissions.role === 'admin';
};
