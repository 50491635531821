import { FC, useEffect, useCallback, useState, useMemo } from 'react';
import {
  createSearchParams,
  Navigate,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { HiFilter, HiPlus, HiOutlineHeart, HiHeart } from 'react-icons/hi';
import {
  Button,
  Card,
  Modal,
  Spinner,
  Table,
  TextInput,
  ToggleSwitch,
} from 'flowbite-react';
import CountUp from 'react-countup';

import { CardContainer } from '../../layout';
import { ClientStatus, VirtualNumberActivationsList } from '../../components';
import { useGlobal } from '../../hooks/useGlobal';
import { canView } from '../../utils/permissions';
import { formatDate } from '../../utils';
import {
  getNumbers,
  getNumberStatistics,
  getVirtualNumberCountries,
  getVirtualNumber,
  getVirtualNumberActivations,
  updateStatusVirtualNumber,
  removeFavoriteCountry,
  selectFavoriteCountry,
  virtualNumberBalance,
} from '../../services';

interface ITimePeriod {
  key: string;
  label: string;
}

const timePeriods: ITimePeriod[] = [
  { key: 'thisWeek', label: 'Bu Hafta' },
  { key: 'lastWeek', label: 'Geçen Hafta' },
  { key: 'thisMonth', label: 'Bu Ay' },
  { key: 'lastMonth', label: 'Geçen Ay' },
];

const numberTypes: ITimePeriod[] = [
  { key: 'virtual', label: 'Sanal' },
  { key: 'physical', label: 'Fiziksel' },
];

interface INumberFilter {
  number: string;
}

const initialNumberFilter: INumberFilter = {
  number: '',
};

export const NumbersMain: FC = () => {
  const navigate = useNavigate();
  const { user, showAlert } = useGlobal();
  const [searchParams] = useSearchParams();

  const [modalCountriesOpen, setModalCountriesOpen] = useState<boolean>(false);
  const [modalCountriesLoading, setModalCountriesLoading] =
    useState<boolean>(false);
  const [modalCountries, setModalCountries] = useState<any[]>([]);
  const [processingCountries, setProcessingCountries] = useState<number[]>([]);
  const [virtualNumberActivations, setVirtualNumberActivations] = useState<
    any[]
  >([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('thisMonth');
  const [selectedNumberType, setSelectedNumberType] = useState<string>('');
  const [numbers, setNumbers] = useState<any[]>([]);
  const [statistics, setStatistics] = useState({
    totalNumbersCount: 0,
    virtualNumbersCount: 0,
    physicalNumbersCount: 0,
    totalTime: 0,
  });
  const [numberFilter, setNumberFilter] =
    useState<INumberFilter>(initialNumberFilter);
  const [withVerification, setWithVerification] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);

  // Redirect to home if user has no permission to view numbers page
  if (!canView(user, 'numbers')) {
    return <Navigate to="/" />;
  }

  // get page actions from user permissions
  const pageAction = useMemo(() => {
    return user?.permissions.pages.numbers.actions;
  }, [user]);

  // fetch numbers
  const fetchNumbers = useCallback(async () => {
    setLoading(true);

    try {
      let filter: any = {
        period: selectedPeriod,
        ...(selectedNumberType ? { numberType: selectedNumberType } : {}),
        sortBy: 'createdAt',
        sortOrder: 'DESC',
      };
      searchParams.forEach((value, key) => {
        filter[key] = value;
      });

      const [numberResponse, statisticsResponse]: any = await Promise.all([
        getNumbers(filter),
        getNumberStatistics({ period: selectedPeriod }),
      ]);

      setNumbers(numberResponse.data);
      setStatistics(statisticsResponse.data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('fetchNumbers error:', error.message);
      } else {
        console.error('fetchNumbers unknown error:', error);
      }
    } finally {
      setLoading(false);
    }
  }, [selectedPeriod, selectedNumberType, searchParams]);

  // fetch virtual number countries
  const fetchVirtualNumberCountries = async () => {
    setModalCountriesLoading(true);
    try {
      const countriesResponse: any = await getVirtualNumberCountries({
        ...(withVerification ? { verification: true } : {}),
      });
      setModalCountries(countriesResponse.data);
    } catch (error) {
      console.error('fetchVirtualNumberCountries:', error);
    } finally {
      setModalCountriesLoading(false);
    }
  };

  // fetch virtual number activations
  const fetchVirtualNumberActivations = async () => {
    try {
      const results: any = await getVirtualNumberActivations();
      setVirtualNumberActivations(results?.data);
    } catch (error) {
      console.log('');
    }
  };

  // new number request
  const handleVirtualNumberRequest = async (country: number) => {
    setProcessingCountries((prev) => [...prev, country]);

    try {
      await getVirtualNumber({ country });
      showAlert({
        text: 'Numara başarıyla oluşturuldu',
        icon: 'success',
      });
    } catch (error: any) {
      showAlert({
        text:
          error?.data?.error ||
          'İşleminiz sırasında hata oluştu, tekrar deneyin',
        icon: 'warning',
      });
    } finally {
      await fetchVirtualNumberActivations();
      setProcessingCountries((prev) => prev.filter((id) => id !== country));
    }
  };

  // update virtual number status
  const handleVirtualNumberUpdateStatus = async (
    id: string,
    status: number,
  ) => {
    try {
      await updateStatusVirtualNumber(id, status);
    } catch (error) {
      console.log('handleVirtualNumberUpdateStatus', error);
    } finally {
      await fetchVirtualNumberActivations();
    }
  };

  // handle period change
  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
  };

  // handle number type change
  const handleNumberTypeChange = (type: string) => {
    setSelectedNumberType((prevType) => (prevType === type ? '' : type));
  };

  // handle filter
  const filterHandler = () => {
    const filtered: any = Object.fromEntries(
      Object.entries(numberFilter).filter(([_, value]) => value !== ''),
    );

    navigate({
      pathname: '/numbers',
      search: createSearchParams(filtered).toString(),
    });
  };

  // handle favorite change
  const handleFavoriteChange = async (item: any) => {
    const countryId = item.country.id;
    if (item.isFavorite) {
      await removeFavoriteCountry(countryId);
    } else {
      await selectFavoriteCountry(countryId);
    }

    await fetchVirtualNumberCountries();
  };

  const getVirtualNumberBalance = async () => {
    const balanceResponse: any = await virtualNumberBalance();
    setBalance(balanceResponse?.data || 0);
  };

  useEffect(() => {
    getVirtualNumberBalance();
  }, []);

  useEffect(() => {
    fetchNumbers();
  }, [searchParams]);

  useEffect(() => {
    fetchVirtualNumberCountries();
  }, [withVerification]);

  useEffect(() => {
    fetchVirtualNumberActivations();
  }, []);

  useEffect(() => {
    let intervalId: any = null;

    if (virtualNumberActivations.length > 0 && !intervalId) {
      intervalId = setInterval(fetchVirtualNumberActivations, 10000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [virtualNumberActivations]);

  return (
    <>
      <div className="items-center flex justify-between mb-4">
        <div className="flex"></div>
        <div className="flex gap-4">
          <Button.Group>
            {numberTypes.map((item, i) => (
              <Button
                color={selectedNumberType === item.key ? 'dark' : 'light'}
                key={`numberTypeBtn-${i}`}
                onClick={() => handleNumberTypeChange(item.key)}
              >
                {item.label}
              </Button>
            ))}
          </Button.Group>
          <Button.Group>
            {timePeriods.map((item, i) => (
              <Button
                color={selectedPeriod === item.key ? 'dark' : 'light'}
                key={`periodBtn-${i}`}
                onClick={() => handlePeriodChange(item.key)}
              >
                {item.label}
              </Button>
            ))}
          </Button.Group>
        </div>
      </div>
      <div className="grid w-full grid-cols-4 gap-4 mb-4">
        <Card>
          <div>
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Toplam Süre
            </h3>
            <div>
              <span className="text-2xl font-bold leading-none text-gray-900">
                <CountUp end={statistics.totalTime} /> saat
              </span>
            </div>
          </div>
        </Card>
        <Card>
          <div>
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Fiziksel Numara
            </h3>
            <div>
              <span className="text-2xl font-bold leading-none text-gray-900">
                <CountUp end={statistics.physicalNumbersCount} />
              </span>
            </div>
          </div>
        </Card>
        <Card>
          <div>
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Sanal Numara
            </h3>
            <div>
              <span className="text-2xl font-bold leading-none text-gray-900">
                <CountUp end={statistics.virtualNumbersCount} />
              </span>
            </div>
          </div>
        </Card>
        <Card>
          <div>
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Toplam Numara
            </h3>
            <div>
              <span className="text-2xl font-bold leading-none text-gray-900">
                <CountUp end={statistics.totalNumbersCount} />
              </span>
            </div>
          </div>
        </Card>
      </div>

      {pageAction && pageAction.createNumber && (
        <VirtualNumberActivationsList
          data={virtualNumberActivations}
          onCancel={(id: string) => handleVirtualNumberUpdateStatus(id, 8)}
          onConfirm={(id: string) => handleVirtualNumberUpdateStatus(id, 6)}
        />
      )}

      <CardContainer title="Numaralar">
        {/* date picker */}
        <div className="items-center flex justify-between">
          <div className="flex gap-4">
            <TextInput
              type="text"
              placeholder="Numara Ara"
              onChange={(e) => {
                setNumberFilter({
                  ...numberFilter,
                  number: e.target.value,
                });
              }}
            />
            <Button color="light" onClick={() => filterHandler()}>
              <HiFilter />
            </Button>
          </div>
          <div className="flex">
            {pageAction && pageAction.createNumber && (
              <div className="flex gap-4 items-center">
                <div className="text-lg font-bold"> Bakiye: {balance} ₽</div>
                <Button
                  color="blue"
                  onClick={() => setModalCountriesOpen(true)}
                >
                  <HiPlus className="mr-2" />
                  Numara Oluştur
                </Button>
              </div>
            )}
          </div>
        </div>
        {/* date picker */}
        <div className="mt-4">
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <Table striped hoverable>
              <Table.Head>
                <Table.HeadCell>Numara</Table.HeadCell>
                <Table.HeadCell>Tip</Table.HeadCell>
                <Table.HeadCell></Table.HeadCell>
                <Table.HeadCell>Connected</Table.HeadCell>
                <Table.HeadCell>Disconnected</Table.HeadCell>
                <Table.HeadCell></Table.HeadCell>
                <Table.HeadCell>
                  <div className="text-center">Toplam Mesaj</div>
                </Table.HeadCell>
                <Table.HeadCell>
                  <div className="text-center">Kişi Kart</div>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {numbers.map((data: any) => {
                  const {
                    _id: id,
                    number,
                    numberType,
                    status,
                    connectionDate,
                    disconnectionDate,
                    messageCount,
                    contactCardCount,
                    totalConnectedTime,
                  } = data;
                  return (
                    <Table.Row
                      key={id}
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    >
                      <Table.Cell>{number}</Table.Cell>
                      <Table.Cell>
                        {numberType === 'physical' ? 'Fiziksel' : 'Sanal'}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="inline-block">
                          {ClientStatus(status)}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        {formatDate(connectionDate, 'DD.MM.YYYY HH:mm:ss')}
                      </Table.Cell>
                      <Table.Cell>
                        {disconnectionDate
                          ? formatDate(disconnectionDate, 'DD.MM.YYYY HH:mm:ss')
                          : null}
                      </Table.Cell>
                      <Table.Cell>{totalConnectedTime}</Table.Cell>
                      <Table.Cell>
                        <div className="text-center">{messageCount}</div>
                      </Table.Cell>
                      <Table.Cell>
                        <div className="text-center">{contactCardCount}</div>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
        </div>
      </CardContainer>
      <Modal
        show={modalCountriesOpen}
        onClose={() => setModalCountriesOpen(false)}
      >
        <Modal.Header>Ülkeler</Modal.Header>
        <Modal.Body className="p-2">
          {modalCountriesLoading ? (
            <div className="text-center p-3">
              <Spinner />
            </div>
          ) : (
            <>
              <div>
                <div className="flex justify-center pb-2 border-b border-gray-200">
                  <ToggleSwitch
                    checked={withVerification}
                    label="Numara ile Doğrulama"
                    onChange={setWithVerification}
                  />
                </div>
                <ul className="divide-y divide-gray-200 ">
                  {modalCountries.map((modalCountry: any, i: number) => {
                    const { country, count, isFavorite } = modalCountry;
                    const isProcessing = processingCountries.includes(
                      country.id,
                    );
                    return (
                      <li className="p-3" key={`modalCountry${i}`}>
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            <Button
                              size="sm"
                              color="gray"
                              pill
                              onClick={() => handleFavoriteChange(modalCountry)}
                            >
                              {isFavorite ? (
                                <HiHeart color="#FF5733" />
                              ) : (
                                <HiOutlineHeart />
                              )}
                            </Button>
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-900 truncate ">
                              {country.eng}
                            </p>
                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                              Adet: {count}
                            </p>
                          </div>
                          <div className="inline-flex items-center">
                            <Button
                              size="sm"
                              color="gray"
                              isProcessing={isProcessing}
                              disabled={isProcessing}
                              onClick={() =>
                                handleVirtualNumberRequest(country.id)
                              }
                            >
                              Oluştur
                            </Button>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
