import { FC, useMemo } from 'react';
import { ListGroup } from 'flowbite-react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useGlobal } from '../hooks/useGlobal';
import { Header, Footer } from '../components';
import { canView } from '../utils/permissions';

const listGroupItems = [
  { to: '/settings', label: 'Kullanıcılar', key: 'users' },
  {
    to: '/message-template',
    label: 'Mesaj Taskları',
    key: 'messageTemplates',
  },
];

export const SettingsLayout: FC = () => {
  const { user } = useGlobal();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Filter menu items based on user permissions
  const filteredMenuItems = useMemo(() => {
    if (!user) return [];

    return listGroupItems.filter(
      (item) => user.permissions.pages[item.key]?.showPage,
    );
  }, [user]);

  // Redirect to login if not logged in
  if (!user) {
    return <Navigate to="/login" />;
  }

  // Redirect to home if user has no permission to view settings page
  if (!canView(user, 'settings') && filteredMenuItems.length === 0) {
    return <Navigate to="/" />;
  }

  // Handle navigation
  const handleNavigation = (path: string) => {
    navigate(path, { replace: true });
  };

  return (
    <>
      <Header user={user} />
      <div className="flex pt-16">
        <div className="relative w-full max-w-screen-xl mx-auto h-full">
          <main className="flex gap-4 my-2">
            <div className="w-[300px]">
              <ListGroup>
                {filteredMenuItems.map((item) => (
                  <ListGroup.Item
                    key={item.to}
                    onClick={() => handleNavigation(item.to)}
                    active={pathname === item.to}
                  >
                    {item.label}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
            <div className="w-full">
              <Outlet />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};
