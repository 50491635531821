import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react';

import { useGlobal } from '../../hooks/useGlobal';
import { auth } from '../../services';
import { IAuth } from '../../types';
import { InputLabel } from '../../components';

export const LoginPage: FC = () => {
  const { login } = useGlobal();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<IAuth>({
    username: '',
    password: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const loginHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const res = await auth(credentials);
      const { user, token } = res.data;

      login(user, token);

      navigate('/', { replace: true });
    } catch (error) {
      alert('Bilgilerinizi kontrol edip tekrar deneyin.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
        <div className="w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
          <h2 className="text-2xl font-bold text-gray-900 text-center">
            WpInvite Giriş
          </h2>
          <form onSubmit={loginHandler} className="mt-8 space-y-6">
            <InputLabel
              title="Kullanıcı Adı"
              name="username"
              required
              onChange={handleInputChange}
              autoComplete="off"
              autoFocus
            />
            <InputLabel
              title="Şifre"
              type="password"
              name="password"
              required
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              isProcessing={loading}
              disabled={loading}
              color="blue"
            >
              Giriş
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
