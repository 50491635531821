import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { HiPencil, HiTrash } from 'react-icons/hi';
import {
  Button,
  Spinner,
  Table,
  Modal,
  TextInput,
  ToggleSwitch,
} from 'flowbite-react';

import {
  deleteUser,
  getUserById,
  getUsers,
  saveUser,
  updateUser,
} from '../../services';
import { CardContainer } from '../../layout';
import { useGlobal } from '../../hooks/useGlobal';
import { canView, isAdmin } from '../../utils/permissions';
import { INewUserPayload } from '../../types';
import { InputLabel } from '../../components';

const UserActions: FC<{
  id: string;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  actions: any;
}> = ({ id, onDelete, onEdit, actions }) => {
  return (
    <Button.Group>
      <Button
        color="gray"
        size="sm"
        disabled={!actions?.edit}
        onClick={() => onEdit(id)}
      >
        <HiPencil />
      </Button>
      <Button
        color="gray"
        size="sm"
        disabled={!actions?.delete}
        onClick={() => onDelete(id)}
      >
        <HiTrash />
      </Button>
    </Button.Group>
  );
};

const initialUserPayload = {
  username: '',
  email: '',
  password: '',
  status: true,
  permissions: {
    role: 'admin',
    pages: {
      settings: {
        showPage: true,
        actions: null,
      },
      numbers: {
        showPage: true,
        actions: {
          createNumber: true,
        },
      },
      statistics: {
        showPage: true,
        actions: null,
      },
      deviceList: {
        showPage: true,
        actions: null,
      },
      deviceDetails: {
        showPage: true,
        actions: {
          edit: true,
          showQr: true,
          testMessage: true,
          actionButtons: true,
        },
      },
      messageTemplates: {
        showPage: true,
        actions: {
          create: true,
          edit: true,
          delete: true,
        },
      },
      users: {
        showPage: true,
        actions: {
          edit: true,
          delete: true,
        },
      },
    },
  },
};

export const UserPage: FC = () => {
  const { user, showAlert } = useGlobal();
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<any[]>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [formBtnLoading, setFormBtnLoading] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<string>('');
  const [newUserPayload, setNewUserPayload] =
    useState<INewUserPayload>(initialUserPayload);

  // Redirect to home if user has no permission to view users page
  if (!canView(user, 'users')) {
    return <Navigate to="/" />;
  }

  const isAdminUser = isAdmin(user);

  // get page actions from user permissions
  const pageAction = useMemo(() => {
    return user?.permissions.pages.users.actions;
  }, [user]);

  // Fetch users
  const fetchUsers = async () => {
    try {
      const response: any = await getUsers();

      setUsers(response.data);
    } catch (error) {
      console.error('fetchUsers:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const openModalCloseHandler = () => {
    setOpenModal(false);
    setEditUser('');
    setNewUserPayload(initialUserPayload);
  };

  // Delete user with confirmation
  const userDeleteHandler = useCallback(async (id: string) => {
    showAlert({
      text: 'Silmek istediğinize emin misiniz?',
      icon: 'question',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteUser(id);
          setUsers((prevUsers: any) =>
            prevUsers.filter((user: any) => user._id !== id),
          );
        } catch (error) {
          console.error('Error deleting user:', error);
        }
      }
    });
  }, []);

  // Edit user
  const userEditHandler = useCallback(async (id: string) => {
    try {
      const response: any = await getUserById(id);
      setNewUserPayload(response.data);
      setEditUser(id);
    } catch (error) {
      console.error('Error editing user:', error);
      setEditUser('');
    } finally {
      setOpenModal(true);
    }
  }, []);

  const handleAddOrEditUser = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setFormBtnLoading(true);

    try {
      if (editUser) {
        await updateUser(editUser, newUserPayload);
      } else {
        await saveUser(newUserPayload);
      }

      await showAlert({
        text: 'İşlem başarılı',
        icon: 'success',
      });

      // fetch users
      await fetchUsers();

      // close modal
      openModalCloseHandler();
    } catch (error: any) {
      showAlert({
        text: error?.data?.error || 'İşlem sırasında bir hata oluştu',
        icon: 'warning',
      });
    } finally {
      setFormBtnLoading(false);
    }
  };

  return (
    <CardContainer
      title="Kullanıcılar"
      action={() =>
        isAdminUser ? (
          <Button size="sm" onClick={() => setOpenModal(true)}>
            Kullanıcı Ekle
          </Button>
        ) : null
      }
    >
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <Table striped hoverable>
          <Table.Head>
            <Table.HeadCell>Username</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {users?.map((item: any) => {
              const { username, email, _id: id } = item;
              return (
                <Table.Row key={id}>
                  <Table.Cell>{username}</Table.Cell>
                  <Table.Cell className="flex justify-end">
                    {isAdminUser ? (
                      <UserActions
                        id={id}
                        onDelete={userDeleteHandler}
                        onEdit={userEditHandler}
                        actions={pageAction}
                      />
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}

      <Modal show={openModal} onClose={() => openModalCloseHandler()}>
        <Modal.Header>
          {editUser ? 'Kullanıcı Düzenle' : 'Kullanıcı Ekle'}
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddOrEditUser}>
            <ToggleSwitch
              className="mb-4"
              checked={newUserPayload.permissions.role === 'admin'}
              label="Admin"
              onChange={(e) => {
                setNewUserPayload((p) => ({
                  ...p,
                  permissions: {
                    ...p.permissions,
                    role: e ? 'admin' : 'user',
                  },
                }));
              }}
            />

            <ToggleSwitch
              className="mb-4"
              checked={newUserPayload.status}
              label="Status"
              onChange={(e) => {
                setNewUserPayload((p) => ({
                  ...p,
                  status: e,
                }));
              }}
            />

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <InputLabel
                  containerClass="mb-4"
                  title="Kullanıcı Adı"
                  onChange={(e: any) => {
                    setNewUserPayload((p) => ({
                      ...p,
                      username: e.target.value,
                    }));
                  }}
                  value={newUserPayload.username}
                  required
                  minLength={3}
                />
              </div>
              <div>
                <InputLabel
                  title="Şifre"
                  onChange={(e: any) => {
                    setNewUserPayload((p) => ({
                      ...p,
                      password: e.target.value,
                    }));
                  }}
                  value={newUserPayload.password}
                  required={!editUser}
                  minLength={6}
                />
              </div>
            </div>

            <div className="w-full ">
              <div className="text-sm font-bold mb-2">Sayfalar / İzinler </div>
              <div className="w-full flex flex-col gap-4">
                <div className="border p-4 rounded-md">
                  <ToggleSwitch
                    checked={
                      newUserPayload.permissions.pages.settings.showPage ||
                      false
                    }
                    label="Ayarlar Sayfası"
                    onChange={(e) => {
                      setNewUserPayload((p) => ({
                        ...p,
                        permissions: {
                          ...p.permissions,
                          pages: {
                            ...p.permissions.pages,
                            settings: {
                              ...p.permissions.pages.settings,
                              showPage: e,
                            },
                          },
                        },
                      }));
                    }}
                  />
                </div>
                <div className="border p-4 rounded-md">
                  <div>
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.numbers.showPage ||
                        false
                      }
                      label="Numaralar Sayfası"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              numbers: {
                                ...p.permissions.pages.numbers,
                                showPage: e,
                              },
                            },
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-2 w-auto gap-4 mt-4 border-t pt-4">
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.numbers.actions
                          .createNumber || false
                      }
                      label="Numara Oluşturma"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              numbers: {
                                ...p.permissions.pages.numbers,
                                actions: {
                                  ...p.permissions.pages.numbers.actions,
                                  createNumber: e,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="border p-4 rounded-md">
                  <ToggleSwitch
                    checked={
                      newUserPayload.permissions.pages.statistics.showPage ||
                      false
                    }
                    label="İstatistikler Sayfası"
                    onChange={(e) => {
                      setNewUserPayload((p) => ({
                        ...p,
                        permissions: {
                          ...p.permissions,
                          pages: {
                            ...p.permissions.pages,
                            statistics: {
                              ...p.permissions.pages.statistics,
                              showPage: e,
                            },
                          },
                        },
                      }));
                    }}
                  />
                </div>
                {/* <div className="border p-4 rounded-md">
                <ToggleSwitch
                  checked={
                    newUserPayload.permissions.pages.deviceList.showPage ||
                    false
                  }
                  label="Cihaz Listesi Sayfası"
                  onChange={(e) => {
                    setNewUserPayload((p) => ({
                      ...p,
                      permissions: {
                        ...p.permissions,
                        pages: {
                          ...p.permissions.pages,
                          deviceList: {
                            ...p.permissions.pages.deviceList,
                            showPage: e,
                          },
                        },
                      },
                    }));
                  }}
                />
              </div> */}
                <div className="border p-4 rounded-md">
                  <div className="text-sm font-medium text-gray-900">
                    Cihaz Detay Sayfası
                  </div>
                  <div className="grid grid-cols-2 w-auto gap-4 mt-4 border-t pt-4">
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.deviceDetails.actions
                          .showQr || false
                      }
                      label="QR Kodu Göster"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              deviceDetails: {
                                ...p.permissions.pages.deviceDetails,
                                actions: {
                                  ...p.permissions.pages.deviceDetails.actions,
                                  showQr: e,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.deviceDetails.actions
                          .testMessage || false
                      }
                      label="Test Mesaj"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              deviceDetails: {
                                ...p.permissions.pages.deviceDetails,
                                actions: {
                                  ...p.permissions.pages.deviceDetails.actions,
                                  testMessage: e,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.deviceDetails.actions
                          .edit || false
                      }
                      label="Düzenleme"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              deviceDetails: {
                                ...p.permissions.pages.deviceDetails,
                                actions: {
                                  ...p.permissions.pages.deviceDetails.actions,
                                  edit: e,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.deviceDetails.actions
                          .actionButtons || false
                      }
                      label="Aksiyon Butonları"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              deviceDetails: {
                                ...p.permissions.pages.deviceDetails,
                                actions: {
                                  ...p.permissions.pages.deviceDetails.actions,
                                  actionButtons: e,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="border p-4 rounded-md">
                  <div>
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.users.showPage || false
                      }
                      label="Kullanıcılar"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              users: {
                                ...p.permissions.pages.users,
                                showPage: e,
                              },
                            },
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-2 w-auto gap-4 mt-4 border-t pt-4">
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.users.actions.edit ||
                        false
                      }
                      label="Düzenleme"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              users: {
                                ...p.permissions.pages.users,
                                actions: {
                                  ...p.permissions.pages.users.actions,
                                  edit: e,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.users.actions.delete ||
                        false
                      }
                      label="Silme"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              users: {
                                ...p.permissions.pages.users,
                                actions: {
                                  ...p.permissions.pages.users.actions,
                                  delete: e,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="border p-4 rounded-md">
                  <div>
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.messageTemplates
                          .showPage || false
                      }
                      label="Mesaj Şablonları"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              messageTemplates: {
                                ...p.permissions.pages.messageTemplates,
                                showPage: e,
                              },
                            },
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-2 w-auto gap-4 mt-4 border-t pt-4">
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.messageTemplates
                          .actions.edit || false
                      }
                      label="Güncelleme"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              messageTemplates: {
                                ...p.permissions.pages.messageTemplates,
                                actions: {
                                  ...p.permissions.pages.messageTemplates
                                    .actions,
                                  edit: e,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                    <ToggleSwitch
                      checked={
                        newUserPayload.permissions.pages.messageTemplates
                          .actions.delete || false
                      }
                      label="Silme"
                      onChange={(e) => {
                        setNewUserPayload((p) => ({
                          ...p,
                          permissions: {
                            ...p.permissions,
                            pages: {
                              ...p.permissions.pages,
                              messageTemplates: {
                                ...p.permissions.pages.messageTemplates,
                                actions: {
                                  ...p.permissions.pages.messageTemplates
                                    .actions,
                                  delete: e,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <Button
                type="submit"
                disabled={formBtnLoading}
                isProcessing={formBtnLoading}
              >
                Kaydet
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </CardContainer>
  );
};
